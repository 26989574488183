<template>
  <div>
    <b-row class="px-2">
      <b-col class="mb-2" cols="4">
        <h1 class="mb-0">Swaps</h1>
      </b-col>
      <b-col class="mb-2" cols="8">
        <div class="d-flex align-items-center justify-content-end">
          <b-button class="primary" @click="$router.push({ name: 'swap.create' })">
            <feather-icon
              icon="ShieldIcon"
              size="12"
            />
            <span class="ml-1 mr-25 align-middle">Cadastrar Swap</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <datatable
      ref="swapsDatatable"
      :customFields="fields"
    >
      <template #table-header-actions>
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-between align-items-center mr-2">
            <flat-pickr
              v-model="data_inicio"
              class="assignor-selector form-control bg-white mr-1"
              :config="config"
              placeholder="Data Inicio"
            />

            <flat-pickr
              v-model="data_fim"
              class="assignor-selector form-control bg-white mr-1"
              :config="config"
              placeholder="Data Fim"
            />
            <b-button variant="outline-secondary" @click="clearDates" class="py-50 px-75">
              <cc-icon icon="CustomEraserIcon" class="text-dark" />
            </b-button>
          </div>
        </div>
      </template>
    </datatable>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow
} from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  moneyFormatDatatable,
  brazilianFormattedDate,
} from '@core/comp-functions/data_visualization/datatable'
import CcIcon from '@/@core/components/cc-icon/CcIcon.vue'
import swap from '@/services/swap'

export default {
  name: 'Swaps',
  components: {
    CcIcon,
    BButton,
    vSelect,
    flatPickr,
    Datatable,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    brazilianFormattedDate,
  },
  data() {
    return {
      showFiltersButton: false,
      datatableFilterValues: {},
      selectedItem: {},
      selectedAssignor: null,
      assignors: [],
      items: [],
      searchByNumber: '',
      fields: [
        {
          label: 'Data inicio',
          key: 'data_inicio',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Data Fim',
          key: 'data_fim',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Cliente Ativo',
          key: 'cliente_ativo',
          formatter: (value) => String(parseFloat(value).toFixed(2)).replace('.', ',') + ' %',
        },
        {
          label: 'Cliente Passivo',
          key: 'cliente_passivo',
          formatter: (value) => String(parseFloat(value).toFixed(4)).replace('.', ',') + ' %',
        },
        {
          label: '',
          key: 'swap_actions',
          destroyAction: this.destroySwap,
        }
      ],
      data_inicio: '',
      data_fim: '',
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
    }
  },
  async mounted() {
  },
  methods: {
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    clearDates() {
      this.data_inicio = ''
      this.data_fim = ''
    },
    async destroySwap(data) {
      console.log(data)
      this.$swal.fire({
        title: 'Atenção!',
        text: 'Deseja realmente excluir este swap?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await swap.delete(data.id)
            this.$swal.fire({
              title: 'Sucesso!',
              text: 'Swap excluído com sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok',
            })
            this.$refs.swapsDatatable.getData()
            this.$swal.fire({
              title: 'Sucesso!',
              text: 'Swap excluído com sucesso!',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
            })
          } catch (error) {
            
          }
        }
      })
    },
  },
  computed: {
    params() {
      let params = {}

      if (this.data_inicio) params.data_inicio = this.data_inicio
      if (this.data_fim) params.data_fim = this.data_fim

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.swapsDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.assignor-selector {
  min-width: 200px;
}
</style>
